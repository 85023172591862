<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="pre-line">
          {{item.name}}
          <small>
            <div v-if="item.actionPlan" class="text-warning">
              แผนกิจกรรม: {{item.actionPlan.name}}
              ({{item.cron}})
            </div>
            <div v-if="item.riskAssessment" class="text-danger">
              ความเสี่ยง: {{item.riskAssessment.name}}
            </div>
            <div v-if="item.targetGraph" class="text-success">
              สรุปผล: {{item.targetGraph.name}}
            </div>
            <div v-if="item.docCategory" class="text-info">
              หมวดหมู่: {{item.docCategory.name}}
            </div>
          </small>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            label="ชื่อ"
            class="col-12"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>

          <DocConfigFormPaperActionPlan
            :templateType="templateType"
            :docType="docType"
            label="แผนกิจกรรม (Action Plan)"
            class="col-12 col-sm-8"
            select="id"
            v-model="formData.actionPlanId"
            :validations="[
              {text: 'required!', value: $v.formData.actionPlanId.$dirty && !$v.formData.actionPlanId.required}
            ]">
          </DocConfigFormPaperActionPlan>

          <sgv-input-text
            label="เวลา (cron)"
            class="col-12 col-sm-4"
            v-model="formData.cron"
            :validations="[
              {text: 'required!', value: $v.formData.cron.$dirty && !$v.formData.cron.required}
            ]">
          </sgv-input-text>

          <DocConfigFormPaperRiskAssessment
            :templateType="templateType"
            :docType="docType"
            label="ประเมินความเสี่ยง"
            class="col-12"
            select="id"
            v-model="formData.riskAssessmentId">
          </DocConfigFormPaperRiskAssessment>

          <DocConfigFormPaperTargetGraph
            :templateType="templateType"
            :docType="docType"
            label="สรุปผล / KPI"
            class="col-12"
            select="id"
            v-model="formData.targetGraphId">
          </DocConfigFormPaperTargetGraph>

          <DocConfigFormDocCategory
            :templateType="templateType"
            :docType="docType"
            label="กลุ่มเอกสาร"
            class="col-12"
            select="id"
            v-model="formData.docCategoryId">
          </DocConfigFormDocCategory>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormPaperTargetGraph from '@/views/dox_core/components/DocConfigFormPaperTargetGraph'
import DocConfigFormPaperRiskAssessment from '@/views/dox_core/components/DocConfigFormPaperRiskAssessment'
import DocConfigFormPaperActionPlan from '@/views/dox_core/components/DocConfigFormPaperActionPlan'
import DocConfigFormDocCategory from '@/views/dox_core/components/DocConfigFormDocCategory'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
        actionPlanId: null,
        cron: '',
        riskAssessmentId: null,
        targetGraphId: null,
        docCategoryId: null
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      cron: {required},
      actionPlanId: {required},
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.$v.formData.$reset()
          this.toggle = false
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.name = this.item.name
          this.formData.cron = this.item.cron
          this.formData.actionPlanId = this.item.actionPlanId
          this.formData.riskAssessmentId = this.item.riskAssessmentId
          this.formData.targetGraphId = this.item.targetGraphId
          this.formData.docCategoryId = this.item.docCategoryId
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.formData.name = ''
        this.formData.cron = ''
        this.formData.actionPlanId = null
        this.formData.riskAssessmentId = null
        this.formData.targetGraphId = null
        this.formData.docCategoryId = null
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DocConfigFormPaperRiskAssessment,
    DocConfigFormPaperTargetGraph,
    DocConfigFormPaperActionPlan,
    DocConfigFormDocCategory
  }
}
</script>

<style lang="css" scoped>
</style>
